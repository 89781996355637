import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { Box, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaAceHwyPOLPortAutocompleteField, NaAceStateCodeAutoCompleteField } from 'src/components/na'
import { ACEHighwayTranslationContext } from '../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import Switch from 'src/components/Switch'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import * as InputSource from 'src/common/InputSource'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: '',
  partyIdentifier: '',
  saveFlag: false,
  name1: '',
  name2: '',
  address1: '',
  address2: '',
  address3: '',
  cityName: '',
  postalCode: '',
  country: '',
  state: '',
  telNo: '',
  email: '',
  ctpatInd: '',
  partyIdentifierCode: '',
  partyIdentifierValue: '',
  phoneNo: '',
  faxNo: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
}

function Fields(props) {
  const { disabled, shouldHideMap, index, pointOfLoading, firstExpectedCode, scn } = props
  const { getValues, setValue, reset, watch, trigger } = useFormContext()
  const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `partyInfo.${index}.${field}` : field
  }, [])

  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [partyIdenPlaceHolder, setPartyIdenPlaceHolder] = useState()
  const [placeHolderValue, setPlaceHolderValue] = useState()
  const [templateDialog, setTemplateDialog] = useState(false)

  const saveFlag = watch(getFieldName('saveFlag'))
  const partyType = watch(getFieldName('partyType'))
  const partyIdentifierCode = watch(getFieldName('partyIdentifierCode'))
  const countryValue = watch(getFieldName('country'))

  useEffect(() => {
    if (partyIdenPlaceHolder === '167') {
      setPlaceHolderValue(getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierValueEIN'))
    } else if (partyIdenPlaceHolder === '161') {
      setPlaceHolderValue(getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierValueSSN'))
    } else if (partyIdenPlaceHolder === '160') {
      setPlaceHolderValue(getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierValueCAN'))
    } else {
      setPlaceHolderValue('')
    }
  }, [partyIdentifierCode])

  useEffect(() => {
    if (getValues(getFieldName('ctpatInd')) === 'Y') {
      setValue(getFieldName('ctpatInd'), true)
    } else if (getValues(getFieldName('ctpatInd')) === 'N') {
      setValue(getFieldName('ctpatInd'), false)
    }
  }, [getValues(getFieldName('ctpatInd'))]);

  useEffect(() => {
    if (partyType !== undefined && partyType === 'GC') {
      setValue(getFieldName('name2'), '')
      setValue(getFieldName('address1'), '')
      setValue(getFieldName('address2'), '')
      setValue(getFieldName('address3'), '')
      setValue(getFieldName('cityName'), '')
      setValue(getFieldName('postalCode'), '')
      setValue(getFieldName('state'), '')
      setValue(getFieldName('country'), '')
      setValue(getFieldName('email'), '')
      setValue(getFieldName('telNo'), '')
      setValue(getFieldName('phoneNo'), '')
      setValue(getFieldName('faxNo'), '')
      setValue(getFieldName('ctpatInd'), false)
    }
    
    if(partyType !== undefined && (partyType !== "GC" && partyType !== "CB" && partyType !== "IM" && partyType !== "NI" )){
      setValue(getFieldName('partyIdentifierCode'), '')
      setValue(getFieldName('partyIdentifierValue'), '')
    }

    if(partyType !== undefined && (partyType !== "CB")){
      setValue(getFieldName('brokerDownloadRefNo'), '')
      setValue(getFieldName('portCode'), '')
      setValue(getFieldName('portOfLoading'), '')
      setValue(getFieldName('routingCode'), '')
    }

    if(partyType !== undefined && partyType === "CB"){
      setValue(getFieldName('partyIdentifierCode'), '')
    }
      
  }, [partyType])

  useEffect(() => {
    if(partyType === "CB"){
      if(!getValues(getFieldName('portCode'))){
        setValue(getFieldName('portCode'), firstExpectedCode, { shouldDirty: true })
      }
      
      setValue(getFieldName('portOfLoading'), pointOfLoading, { shouldDirty: true })
      setValue(getFieldName('brokerDownloadRefNo'), scn, { shouldDirty: true })
      trigger()
    }

  }, [pointOfLoading, partyType, scn])
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue(getFieldName('fileForUserId'), fileForUserDetails.fileForUserId)
      setValue(getFieldName('fileForUserLoginId'), fileForUserDetails.fileForUserLoginId)
      setValue(getFieldName('fileForUserPartyId'), fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    Promise.all([
      // Crew Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([acePartyType]) => {
      const partyMasterConvType = { 'CZ': 'Shipper', 'SH': 'Shipper' };
      const partyType = { ...acePartyType, ...partyMasterConvType };
      setLookups({ partyType })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'partytype',
      title: getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      field: 'partyidn',
      title: getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifier')
    },
    {
      title: `${getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'name1')}/${getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'name2')}`,
      render: (data) => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'country')
    },
    {
      field: 'statecode',
      title: getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'state')
    },
  ]

  function handleApplyTemplate(template) {
    if (!template) {
      reset()
      return
    }

      const {
        partyidn,
        partytype,
        partyname1,
        partyname2,
        address1,
        address2,
        address3,
        cityname,
        postalcode,
        statecode,
        countrycode,
        ctpatInd,
        email,
        faxNo,
        telephonenumber,
        partyIdentifier
      } = template

      const partyType = _.isEmpty(partytype) ? '' : partytype.slice(-2)

    const convertPartyType = [{ id: 'OS', value: 'CZ' }, { id: 'OS', value: 'SH' }] //CZ, SH is Shipper in other module

    var matchPartyObj = (convertPartyType.find(obj => {
        return obj.id === partyType || obj.value === partyType;
      }))
      var convPartyType = partyType ? undefined !== matchPartyObj ? matchPartyObj.id : partyType : partyType;

    setValue(getFieldName('partyIdentifier'), partyidn, { shouldDirty: true })
    setValue(getFieldName('partyType'), convPartyType, { shouldDirty: true })
    setValue(getFieldName('name1'), partyname1 || '', { shouldDirty: true })
    setValue(getFieldName('name2'), partyname2 || '', { shouldDirty: true })
    setValue(getFieldName('address1'), address1 || '', { shouldDirty: true })
    setValue(getFieldName('address2'), address2 || '', { shouldDirty: true })
    setValue(getFieldName('address3'), address3 || '', { shouldDirty: true })
    setValue(getFieldName('cityName'), cityname || '', { shouldDirty: true })
    setValue(getFieldName('postalCode'), postalcode || '', { shouldDirty: true })
    setValue(getFieldName('state'), statecode || '', { shouldDirty: true })
    setValue(getFieldName('country'), countrycode || '', { shouldDirty: true })
    setValue(getFieldName('ctpatInd'), ctpatInd === 'Y' ? true : false, { shouldDirty: true })

      //for custom broker
      if (partyType === 'CB') {
        setValue(getFieldName('email'), email || '', { shouldDirty: true })
        setValue(getFieldName('faxNo'), faxNo || '', { shouldDirty: true })
        setValue(getFieldName('phoneNo'), telephonenumber || '', { shouldDirty: true })
      }
      
      if (partyType === 'NI' || partyType === 'IM' || partyType === 'GC' || partyType === 'CB') {
        if (partyIdentifier !== null && partyIdentifier !== undefined && partyIdentifier.length > 0) {
          const { partyIdentifierCode, partyIdentifierNumber } = partyIdentifier[partyIdentifier.length - 1]
          setValue(getFieldName('partyIdentifierCode'), partyIdentifierCode || '', { shouldDirty: true })
          setValue(getFieldName('partyIdentifierValue'), partyIdentifierNumber || '', { shouldDirty: true })
          if (partyType === 'CB') {
            setValue(getFieldName('routingCode'), (firstExpectedCode + partyIdentifierNumber) || '', { shouldDirty: true })
          }
        }
      }

    trigger()
  }

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2}>
          <CngGridItem xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
                <CngCodeMasterAutocompleteField
                  required
                  name={getFieldName('partyType')}
                  label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyType')}
                  disabled={disabled}
                  codeType='ACE_HW_PARTY_TYPE'
                  key={partyType}
                  size='small'
                  onChange={(value) => setValue(getFieldName('partyType'), value)}
                />
              </CngGridItem>

              <CngGridItem xs={12} lg={8}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.saveFlag}>
                    <CngCheckboxField
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'saveFlag')}
                        </Typography>
                      }
                      name={getFieldName('saveFlag')}
                      onChange={(e) => setValue(getFieldName('saveFlag'), e.target.checked)}
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md shouldHide={shouldHideMap?.partyIdentifier}>
                    <CngTextField
                      required
                      name={getFieldName('partyIdentifier')}
                      inputProps={{ maxLength: 35 }}
                      label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifier')}
                      disabled={disabled || !saveFlag}
                      onBlur={(e) => {
                        setValue(getFieldName('partyIdentifier'), e.target.value.toUpperCase().trim())
                      }}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md={4}>
                    <NaAcePartyMasterAutoCompleteField
                      name={getFieldName('partyDropDown')}
                      label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyDropDown')}
                      disabled={disabled}
                      onChange={(_, options) => handleApplyTemplate(options.data)}
                      lookupProps={{
                        filters: [{
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        },
                          { field: 'partytype', operator: IN, value: (partyType === 'CN' ? ['CN'] : partyType === 'OS' ? ['OS', 'CZ', 'SH'] : partyType) } //CZ, SH is Shipper in other module
                      ]
                      }}
                      size='small'
                      fullWidth
                      disableClearable
                      textFieldProps={{
                        InputProps: {
                          customEndAdornment: () => (
                            <InputAdornment position='end' style={{ marginTop: -16 }}>
                              <IconButton onClick={(event) => {
                                event.stopPropagation()
                                setTemplateDialog(true)
                              }}>
                                <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                  <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                      forcePopupIcon={false}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>

              <CngGridItem xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.name1}>
                    <CngTextField
                      required={partyType !== 'GC'}
                      name={getFieldName('name1')}
                      label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'name1')}
                      disabled={disabled}
                      onBlur={(e) => {
                        setValue(getFieldName('name1'), e.target.value.toUpperCase().trim())
                      }}
                      size='small'
                    />
                  </CngGridItem>

                  {partyType !== null && partyType == 'CB' &&
                    <>
                      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.phoneNo}>
                        <CngTextField
                        name={getFieldName('phoneNo')}
                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'phoneNo')}
                        disabled={disabled}
                        onBlur={(e) => {
                          setValue(getFieldName('phoneNo'), e.target.value.toUpperCase().trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.faxNo}>
                      <CngTextField
                        name={getFieldName('faxNo')}
                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'faxNo')}
                        disabled={disabled}
                        onBlur={(e) => {
                          setValue(getFieldName('faxNo'), e.target.value.toUpperCase().trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.email}>
                      <CngTextField
                        name={getFieldName('email')}
                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'email')}
                        disabled={disabled}
                        onBlur={(e) => {
                          setValue(getFieldName('email'), e.target.value.toUpperCase().trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                  </>
                  }

                  {partyType !== null && (partyType === '' || partyType === 'OS' || partyType === 'CN') && (
                    <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.name2}>
                        <CngTextField
                          name={getFieldName('name2')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'name2')}
                          disabled={disabled}
                          onBlur={(e) => {
                            setValue(getFieldName('name2'), e.target.value.toUpperCase().trim())
                          }}
                          size='small'
                        />
                      </CngGridItem>
                  )}

                </Grid>
              </CngGridItem>

              {partyType !== null && (
                <>
                  {(partyType === '' || partyType === 'OS' || partyType === 'CN') && (
                    <CngGridItem xs={12}>
                      <Grid container spacing={1}>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.address1}>
                          <CngTextField
                            required
                            name={getFieldName('address1')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'address1')}
                            disabled={disabled}
                            onBlur={(e) => {
                              setValue(getFieldName('address1'), e.target.value.toUpperCase().trim())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.address2}>
                          <CngTextField
                            name={getFieldName('address2')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'address2')}
                            disabled={disabled}
                            onBlur={(e) => {
                              setValue(getFieldName('address2'), e.target.value.toUpperCase().trim())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.address3}>
                          <CngTextField
                            name={getFieldName('address3')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'address3')}
                            disabled={disabled}
                            onBlur={(e) => {
                              setValue(getFieldName('address3'), e.target.value.toUpperCase().trim())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.cityName}                        >
                          <CngTextField
                            required
                            name={getFieldName('cityName')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'cityName')}
                            disabled={disabled}
                            onBlur={(e) => {
                              setValue(getFieldName('cityName'), e.target.value.toUpperCase().trim())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.postalCode}>
                          <CngTextField
                            required
                            name={getFieldName('postalCode')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'postalCode')}
                            disabled={disabled}
                            onBlur={(e) => {
                              setValue(getFieldName('postalCode'), e.target.value.toUpperCase().trim())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.country}>
                          <CngCountryAutocompleteField
                            required
                            name={getFieldName('country')}
                            inputProps={{ maxLength: 2 }}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'country')}
                            disabled={disabled}
                            onChange={() => setValue(getFieldName('state'), '')}
                            key={countryValue}
                            lookupProps={{
                              label: (record) => `${record.code};${record.descriptionEn}`
                            }}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.state}>
                          <NaAceStateCodeAutoCompleteField
                            required
                            name={getFieldName('state')}
                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'state')}
                            disabled={disabled}
                            countryCode={countryValue}
                            key={countryValue}
                            size='small'
                          />
                        </CngGridItem>
                      </Grid>
                    </CngGridItem>
                  )}

                  {(partyType === 'NI' || partyType === 'IM' || partyType === 'GC') && (
                    <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.partyIdentifierCode}>
                      {(partyType === 'NI' || partyType === 'IM') && (
                        <CngCodeMasterAutocompleteField
                          required
                          name={getFieldName('partyIdentifierCode')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierCode')}
                          disabled={disabled}
                          codeType='ACE_HW_IM_PARTY_IDN_CODE'
                          onChange={(e) => {
                            setPartyIdenPlaceHolder(e)
                            setValue(getFieldName('partyIdentifierValue'), '')
                          }}
                          size='small'
                          key={getValues(getFieldName('partyIdentifierCode'))}
                        />
                      )}

                      {partyType === 'GC' && (
                        <CngCodeMasterAutocompleteField
                          required
                          name={getFieldName('partyIdentifierCode')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierCode')}
                          disabled={disabled}
                          codeType='ACE_HW_GC_PARTY_IDN_CODE'
                          onChange={(e) => {
                            setPartyIdenPlaceHolder(e)
                            setValue(getFieldName('partyIdentifierValue'), '')
                          }}
                          size='small'
                          key={getValues(getFieldName('partyIdentifierCode'))}
                        />
                      )}

                    </CngGridItem>
                  )}

                  {(partyType === 'NI' || partyType === 'GC' || partyType === 'IM') && (
                    <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.partyIdentifierValue}>
                      <CngTextField
                        required
                        name={getFieldName('partyIdentifierValue')}
                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierValue')}
                        disabled={disabled}
                        onChange={(e) => {
                          let word = e.target.value.toUpperCase()
                          let word2 = word.substring(4, 10)

                          if (partyIdentifierCode === '167' && word.length === 3 && !word.includes('-')) {
                            word = word.substring(0, 2) + '-' + word.substring(2, 10)
                          } else if (partyIdentifierCode === '161' && word.length === 4 && !word.includes('-')) {
                            word = word.substring(0, 3) + '-' + word.substring(3, 6)
                          } else if (partyIdentifierCode === '161' && word2.length === 3 && !word2.includes('-')) {
                            word = word.substring(0, 6) + '-' + word.substring(6, 10)
                          } else if (partyIdentifierCode === '160' && word.length === 7 && !word.includes('-')) {
                            word = word.substring(0, 6) + '-' + word.substring(6, 11)
                          }

                          setValue(getFieldName('partyIdentifierValue'), word)
                        }}
                        onBlur={(e) => {
                          setValue(getFieldName('partyIdentifierValue'), e.target.value.trim())
                        }}
                        inputProps={{
                          maxLength: partyIdentifierCode === '161' ? 11 : 12
                        }}
                        placeholder={placeHolderValue}
                        helperText={
                          partyIdentifierCode === '160' || partyIdentifierCode === '161' || partyIdentifierCode === '167'
                            ? getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partyIdentifierValueNote')
                            : ''
                        }
                        size='small'
                      />
                    </CngGridItem>
                  )}

                  {partyType === 'CB' && (
                    <>
                    <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.partyIdentifierValue}>
                      <CngTextField
                        required
                        name={getFieldName('partyIdentifierValue')}
                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'filerCode')}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue(getFieldName('routingCode'), getValues(getFieldName('portCode')) + e.target.value)
                        }}
                        onBlur={(e) => {
                          setValue(getFieldName('partyIdentifierValue'), e.target.value.trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.portCode}>
                        <CngTextField
                          required
                          name={getFieldName('portCode')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'portCode')}
                          disabled={disabled}
                          onChange={(e) => {
                            setValue(getFieldName('routingCode'), e.target.value + getValues(getFieldName('partyIdentifierValue')))
                          }}
                          size='small'
                          onBlur={(e) => {
                            setValue(getFieldName('portCode'), e.target.value.toUpperCase().trim())
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.routingCode}>
                        <CngTextField
                          name={getFieldName('routingCode')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'routingCode')}
                          disabled={true}
                          onBlur={(e) => {
                            setValue(getFieldName('routingCode'), e.target.value.toUpperCase().trim())
                          }}
                          InputLabelProps={{ shrink: getValues(getFieldName('routingCode')) }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.brokerDownloadRefNo}>
                        <CngTextField
                          required
                          name={getFieldName('brokerDownloadRefNo')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'brokerDownloadRefNo')}
                          disabled={disabled}
                          onChange={(e) => {
                            setValue(getFieldName('brokerDownloadRefNo'),e.target.value.toUpperCase())
                          }}
                          onBlur={(e) => {
                            setValue(getFieldName('brokerDownloadRefNo'), e.target.value.toUpperCase().trim())
                          }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.portOfLoading}>
                        <NaAceHwyPOLPortAutocompleteField
                          required
                          name={getFieldName('portOfLoading')}
                          label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'portOfLoading')}
                          disabled={disabled}
                          size='small'
                          key={getValues(getFieldName('portOfLoading'))}
                        />
                      </CngGridItem>
                    </>
                  )}
                </>
              )}

            </Grid>
          </CngGridItem>

          {partyType !== null && (partyType === '' || partyType === 'OS' || partyType === 'CN') && (
              <CngGridItem xs={12}>
                <CngSection title='Other Details'>
                <Grid container spacing={1}>                    
                  <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.ctpatInd}>
                    <Switch name={getFieldName('ctpatInd')} label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'ctpatInd')} disabled={disabled} fullWidth />
                    </CngGridItem>
                  <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.telNo}>
                      <CngTextField
                      name={getFieldName('telNo')}
                      label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'telNo')}
                      disabled={disabled}
                        onBlur={(e) => {
                          setValue(getFieldName('telNo'), e.target.value.toUpperCase().trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                  <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.email}>
                      <CngTextField
                      name={getFieldName('email')}
                      label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'email')}
                      disabled={disabled}
                        onBlur={(e) => {
                          setValue(getFieldName('email'), e.target.value.toUpperCase().trim())
                        }}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </CngSection>
              </CngGridItem>
            )}

        </Grid>
      </Box>

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: (partyType === 'CN' ? ['CN'] : partyType === 'OS' ? ['OS', 'CZ', 'SH'] : partyType) }, //CZ, SH is Shipper in other module
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
          { field: 'inputSource', operator: EQUAL, value: InputSource.ACE_HWY_SHIPMENT }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }}
        title='Party Template'
      />
    </>
  )
}

const PartyInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyInfoFormProperties
